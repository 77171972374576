import React, { useState, useEffect } from "react";
import "./LogoGrid.scss";
import { Waypoint } from "react-waypoint";
export default function ({ content, items }) {
  const [active, setActive] = useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`logo-grid external theme-dark waypoint ${
          active ? "waypoint-active" : ""
        }`}
      >
        <div className="header-group align-center">
          <h2 className="heading heading--xs uppercase">
            {content.grid_title.text}
          </h2>
        </div>
        <div className="logo-grid__items">
          {items.map((client, index) => {
            if (client.link) {
              return (
                <a
                  href={client.link.url}
                  target={client.link.target}
                  key={`lg-${index}`}
                  className={`logo-grid__item-external item-${index}`}
                >
                  <img src={client.logo.fluid.src} alt={client.logo.alt} />
                </a>
              );
            }

            return (
              <div
                key={`lg-${index}`}
                className={`logo-grid__item-external item-${index}`}
              >
                <img src={client.logo.fluid.src} alt={client.logo.alt} />
              </div>
            );
          })}
        </div>
      </div>
    </Waypoint>
  );
}
