import React, { useState, useEffect } from "react";
import { Waypoint } from "react-waypoint";
export default function () {
  const [active, setActive] = useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };
  
  useEffect(() => {
    // add widget to end of body and run it
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = "https://widget.clutch.co/static/js/widget.js"
    script.async = true
    document.body.appendChild(script)

    script.onload = () => {
      window.CLUTCHCO.Init()
    }
    return () => {
      window.CLUTCHCO.Destroy()
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Waypoint ypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
          style={{ backgroundColor: "#fafafa" }}
          className={`clutch-reviews waypoint ${
            active ? "waypoint-active" : ""
          }`}
        >
        <script
          type="text/javascript"
          src="https://widget.clutch.co/static/js/widget.js"
        ></script>
        <div>
          <div
            className="clutch-widget"
            style={{
              backgroundColor: "#fafafa",
              maxWidth: "1400px",
              margin: "0 auto",
            }}
            data-url="https://widget.clutch.co"
            data-widget-type="12"
            data-height="375"
            data-nofollow="true"
            data-expandifr="true"
            data-scale="100"
            data-primary-color="#b52e7f"
            data-secondary-color="#b52e7f"
            data-reviews="2140874,2094098,2077215,2030911,2005181,1960189,1939361,1893990,1889270,1830551,1788286,1781639"
            data-clutchcompany-id="1222088"
          ></div>
        </div>
      </div>
    </Waypoint>
  );
}
