import React, { useState, useEffect, useRef } from "react";
import "./BlockQuote.scss";
import Slider from "react-slick";
import { Waypoint } from "react-waypoint";

const BlockQuoteNav = () => {
  return (
    <div className="block-quote-nav">
      <ul>
        <li>
          <button className="block-quote-nav__button"></button>
        </li>
        <li>
          <button className="block-quote-nav__button"></button>
        </li>
        <li>
          <button className="block-quote-nav__button"></button>
        </li>
      </ul>
    </div>
  );
};

const BlockQuote = ({ content, items, item, theme }) => {
  const quoteItems = item ? [item] : items;

  const [current, setCurrent] = useState(0);

  const [active, setActive] = useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  const sliderRef = useRef(null);

  const OPTIONS = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    beforeChange: (current, next) => {
      setCurrent(next);
    },
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`block-quote theme-${theme} waypoint ${
          active ? "waypoint-active" : ""
        }`}
      >
        <div className="block-quote__inner">
          <h3 className="block-quote__label text--medium uppercase text--m">
            {content.quote_title.text}
          </h3>
          <div className="block-quote__seperator"></div>
          <div className="block-quote__items">
            <Slider ref={sliderRef} {...OPTIONS}>
              {quoteItems.map(({ quote_text, quote_company, quote_person }) => {
                return (
                  <div className="quote-item">
                    <div className="row">
                      <div className="col col-md-8 col-md-offset-2">
                        <div class="quote heading heading--xl">
                          "{quote_text.text}"
                        </div>
                        <div className="block-quote__caption">
                          <h6 class="text text--medium text--s uppercase">
                            {quote_person}
                          </h6>
                          <h5 className="heading heading--s text--medium uppercase">
                            {quote_company}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
        {quoteItems.length > 1 && (
          <div className="block-quote-nav">
            <ul>
              {quoteItems.map(({ author, client, quote }, index) => {
                return (
                  <li>
                    <button
                      onClick={() => {
                        sliderRef.current.slickGoTo(index);
                      }}
                      className={`block-quote-nav__button ${
                        index === current ? "active" : ""
                      }`}
                    ></button>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </Waypoint>
  );
};
export default BlockQuote;
